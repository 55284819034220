@use '../utils/_variables' as *

.carousel
    width: 100vw
    height: 100vh
    position: fixed
    top: 0
    left: 0
    background-color: rgba(0,0,0,0.85)
    z-index: 2000
    overflow: hidden
    display: flex
    flex-direction: row
    @media (max-width: $mobileSize)
        flex-direction: column
    &__close
        position: absolute
        top: 15px
        left: 15px
        color: var(--light-background)
        font-size: 25px
        cursor: pointer
        @media (max-width: $mobileSize)
            left: initial
            right: 15px
            top: 10px
            font-size: 35px
    &__container
        width: calc( 100% - 180px )
        height: calc( 100% - 80px )
        padding: 15px
        margin-top: 50px
        @media (max-width: $mobileSize)
            width: calc( 100vw - 30px)
            height: calc( 80vh - 80px )
        &--leftAside
            position: absolute
            right: -100%
            // width: 100%
            width: calc( 100% - 180px )
            height: 100%
            object-fit: contain
            @media (max-width: $mobileSize)
                width: calc( 100vw - 30px)
                height: calc( 80vh - 80px )
        &--rightAside
            position: absolute
            left: -100%
            width: calc( 100% - 180px )
            height: 100%
            object-fit: contain
            @media (max-width: $mobileSize)
                width: calc( 100vw - 30px)
                height: calc( 80vh - 80px )
        & img
            // display: none
            width: 100%
            height: 100%
            object-fit: contain
    &__preview
        width: 180px
        padding: 15px 0
        display: flex
        flex-direction: column
        align-items: center
        gap: 15px
        overflow-y: scroll
        background-color: rgb(0,0,0)
        @media (max-width: $mobileSize)
            // width: 100vw
            width: calc( 100vw - 30px)
            height: calc( 20vh - 30px)
            padding: 15px
            flex-direction: row
        &__image
            opacity: 0.7
            // margin: 0 auto
            cursor: pointer
            transition: 0.2s ease-in-out
            height: 100%
            &--active
                opacity: 1
                margin: -2.5px 2.5px 2.5px -2.5px 
                box-shadow: rgb(255, 255, 255) 10px 10px 30px -10px
                @media (max-width: $mobileSize)
                    box-shadow: rgb(255, 255, 255) 5px 5px 25px -10px
            &:hover
                opacity: 1
            & img
                width: 150px
                @media (max-width: $mobileSize)
                    height: 100%
                    width: initial
                    object-fit: contain


