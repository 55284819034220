@use '../utils/_variables' as *

.legal
    min-height: 90vh
    width: 90%
    margin: 20px auto 40px auto
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    & h1
        text-align: center
    &__ariane
        text-align: center
        margin-bottom: 40px
        & a
            color: var(--text-high)
            font-weight: bold
    & h2
        text-align: left
        margin-top: 40px
    & p 
        & a
            color: var(--text-high)
            font-weight: bold