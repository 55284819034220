@use '../utils/_variables' as *

.settings
    position: fixed
    top: 20px 
    right: 20px
    display: flex
    flex-direction: column
    align-items: center
    gap: 15px
    z-index: 200
    @media (max-width: $mobileSize)
        flex-direction: row-reverse
    &__theme:hover
        cursor: pointer
    &__language
        display: flex
        flex-direction: column
        align-items: center
        gap: 10px
        @media (max-width: $mobileSize)
            flex-direction: row
        &:hover
            cursor: pointer
            .settings__language__cursor
                border: 1px solid var(--border-hovered)
                transition: border 0.3s ease-in-out
        &:active
            .settings__language__cursor
                border: 1px solid var(--border-selected)
        &__lng
            font-size: 16px
        &__cursor
            width: 20px
            height: 40px
            border-radius: 10px
            border: 1px solid var(--border)
            background-color: var(--background)
            display: flex
            flex-direction: column
            align-items: center
            justify-content: start
            transition: border 0.2s ease-in-out
            @media (max-width: $mobileSize)
                flex-direction: row
                height: 20px
                width: 40px
            &__pointer
                transition: 0.2s ease-in-out
                position: relative
                top: 0
                right: 0
                width: 13px
                height: 13px
                margin: 3.5px
                border-radius: 50%
                background-color: var(--solid)
                &--bottom
                    transition: 0.2s ease-in-out
                    position: relative
                    top: 20px
                    width: 13px
                    height: 13px
                    margin: 3.5px
                    border-radius: 50%
                    background-color: var(--solid)
                &--right
                    transition: 0.2s ease-in-out
                    position: relative
                    top: initial
                    right: -20px
                    width: 13px
                    height: 13px
                    margin: 3.5px
                    border-radius: 50%
                    background-color: var(--solid)
