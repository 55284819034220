@use '../utils/_variables' as *

.modal
    height: 100vh
    width: 100vw
    position: fixed
    top: 0
    left: 0
    background-color: rgba(0,0,0,0.7)
    display: none
    justify-content: center
    align-items: center
    z-index: 1000
    &__content
        width: fit-content
        height: fit-content
        max-width: 95vw
        max-height:  95vh
        min-width: 50vw
        min-height: 450px
        padding: 20px
        border-radius: 8px
        background-color: var(--background)
        display: flex
        flex-direction: column
        align-items: center
        &__close
            position: relative
            align-self: end
            color: var(--danger)
            cursor: pointer
            font-size: 30px
            &:hover
                color: var(--danger-hovered)
        &__title
            text-align: center
            font-size: 20px