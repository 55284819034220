@use '../utils/_variables' as *

.projectPage
    display: flex
    flex-direction: column
    &__loader
        width: 100vw
        height: 100vh
        position: absolute
        top: 0
        left: 0
        display: flex
        justify-content: center
        align-items: center
        & svg
            font-size: 50px
    &__return
        position: fixed
        top: 40px
        left: 40px
        font-size: 30px
        cursor: pointer
        @media ( max-width: $mobileSize )
            top: 20px
            left: 20px
    & article
        border-bottom: 1px solid var(--text-high)
    &__title
        @media ( max-width: $mobileSize )
            margin-top: 50px
    &__date
        align-self: center
    &__synopsis
        font-size: 18px
        font-weight: 500
    &__carrousel
        width: 100%
        // height: 60dvh
        align-self: center
        margin: 25px auto
        @media ( max-width: $mobileSize )
            width: 100vw
            height: auto
            position: relative
            // left: -2.5vw
            margin: 0
        & img
            height: 100%
            width: 100%
            object-fit: contain
    &__network
        display: flex
        flex-direction: row
        align-items: center
        justify-content: space-around
        font-size: 30px
        margin: 15px 0
        padding: 15px 0
        border-top: 1.5px solid var(--warning)
        border-bottom: 1px solid var(--warning)
        @media ( max-width: $mobileSize )
            font-size: 16px
            flex-wrap: wrap
        &__link
            display: flex
            align-items: center
            justify-content: center
            gap: 10px
            color: var(--text-high)
            transition: 0.2s ease-in-out
            & svg
                font-size: 27px
                color: var(--warning-hovered)
                @media ( max-width: $mobileSize )
                    font-size: 18px
            &:hover
                color: var(--background)
                background-color: var(--text-high)
                padding: 5px 10px
                border-radius: 7px
                margin: -5px -10px
        &__github
            transition: 0.2s ease-in-out
            & img
                height: 40px
            &:hover
                padding: 5px 10px
                margin: -5px -10px
                border-radius: 7px
                background-color: var(--warning)
        &__linkedin
            display: flex
            align-items: center
            gap: 10px
            transition: 0.2s ease-in-out
            border: 1px solid var(--background-subtle)
            & img
                height: 35px
                @media ( max-width: $mobileSize )
                    height: 20px
            &:hover
                padding: 5px 10px
                margin: -5px -10px
                border: 1px solid var(--warning)
                border-radius: 7px
                
    &__content
    &__skills
        display: flex
        flex-direction: row
        flex-wrap: wrap
        gap: 20px
        margin: 10px 0
        padding: 10px 0
        @media ( max-width: $mobileSize )
            justify-content: space-between
            gap: 8px
        &__item
            padding: 2.5px 5px
            border: 1px solid var(--text-high)
            border-radius: 5px
    &__images
        display: flex
        justify-content: center
        flex-wrap: wrap
        gap: 15px
        &__item
            width: 200px
            height: 160px
            cursor: pointer
            & img
                width: 95%
                height: 95%
                object-fit: contain
                transition: 0.2s ease-in-out
            &:hover
                & img
                    width: 100%
                    height: 100%
    &__imagesGroup
        height: 175px
        width: fit-content
        margin: 0 auto
        cursor: pointer
        transition: 0.2s ease-in-out
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        &:hover
            & .projectPage__imagesGroup__item
                &:nth-child(1)
                    margin: -20px -20px 20px 20px
                &:nth-child(2)
                    margin: -15px -15px 15px 15px
                &:nth-child(3)
                    margin: -10px -10px 10px 10px
                &:nth-child(4)
                    margin: -5px -5px 5px 5px
                &:last-child
                    position: relative
        &__item
            position: absolute
            transition: 0.2s ease-in-out
            &:nth-child(1)
                margin: -12px -12px 12px 12px
            &:nth-child(2)
                margin: -9px -9px 9px 9px
            &:nth-child(3)
                margin: -6px -6px 6px 6px
            &:nth-child(4)
                margin: -3px -3px 3px 3px
            &:last-child
                position: relative
            & img
                height: 100px
                width: 200px
                object-fit: cover
        &__name
            
                
    &__recommendations
        &__title
            margin: 30px auto
            font-size: 28px
            font-weight: bold
        &__container
            display: flex
            flex-direction: row
            align-items: center
            justify-content: space-around
            flex-wrap: wrap
            gap: 30px
            margin-bottom: 30px
            @media ( max-width: $mobileSize )
                gap: 10px
                
    // & img
    //     max-width: 100%