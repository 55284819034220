@use '../utils/_variables' as *

.adminProjects
    min-height: calc( 100vh - 250px)
    &__form
        display: flex
        flex-direction: column
        gap: 15px
        @media (max-width: $tabletSize)
            width: 100%

        & input
            width: 80%
            border: 1px solid var(--background)
            outline: none
            border-radius: 4px
            padding: 10px 20px
            color: var(--text-high)
            background-color: var(--element)
            font-size: 18px
            &:focus
                // margin: -0.75px 0
                border: 1px solid var(--border-selected)
                background-color: var(--background-subtle)
            // &:valid
            //     background-color: var(--form-success)

        
        & textarea
            outline: none
            border: 1px solid var(--background)
            width: 80%
            height: 50vh
            max-height: 350px
            border-radius: 8px
            background-color: var(--element)
            color: var(--text-high)
            padding: 10px 20px
            font-size: 18px
            resize: none
            &:focus
                background-color: var(--background-subtle)
                border: 1px solid var(--border-selected)
                // outline: 1px solid var(--border-selected)
                // margin: 0.1px
            &:valid
                background-color: var(--form-success)

        & select
            // width: 80%
            border: 1px solid var(--background)
            outline: none
            border-radius: 4px
            padding: 10px 20px
            color: var(--text-high)
            background-color: var(--element)
            font-size: 18px

        
        & button
            width: fit-content
            align-self: center
            outline: none
            border: none
            // border: 1px solid var(--border)
            border-radius: 6px
            background-color: var(--element)
            color: var(--text-high)
            padding: 8px 15px
            font-size: 17px
            font-weight: bold
            &:hover
                // border: 1px solid var(--border-hovered)
                background-color: var(--background-subtle)
                cursor: pointer
            &:focus
                // border: 1px solid var(--border-hovered)
                background-color: var(--background-subtle)
            // &:active
                border: 1px solid var(--border-selected)
        &__skillList
            display: flex
            flex-direction: row
            align-items: center
            flex-wrap: wrap
            gap: 15px
            &__selected
                padding: 2.5px 5px
                border: 1px solid var(--text-high)
                border-radius: 5px
                display: flex
                flex-direction: row
                align-items: center
                gap: 3px
                & svg 
                    cursor: pointer
                &__star
                    color: var(--warning-hovered)
                &__emptyStar
                    &:hover
                        color: var(--warning-hovered)
                &__remove
                    position: relative
                    color: var(--danger-hovered)
                    background-color: var(--background-subtle)
                    top: -13px
                    right: -10px
            &__item
                padding: 2.5px 5px
                border: 1px solid var(--text-high)
                border-radius: 5px
                opacity: 0.6
                cursor: pointer
                &:hover
                    border: 1px solid var(--warning-hovered)
                    color: var(--warning-hovered)
                    background-color: var(--text-high)
        &__imagesPreview
            display: flex
            flex-direction: row
            align-items: end
            gap: 5px
            &__item
                width: 140px
                display: flex
                flex-direction: column
                align-items: center
                gap: 5px
                & img
                    cursor: pointer
                & div
                    width: 100%
                    display: flex
                    justify-content: center
                    gap: 25px
                    & span
                        cursor: pointer
                        & svg
                            font-size: 20px
                        &:nth-child(2n) 
                            color: var(--danger)
    &__projects
        display: flex
        flex-direction: row
        flex-wrap: wrap
        gap: 20px


    &__projectCard
        width: 200px
        padding: 15px
        border: 1px solid var(--border)
        border-radius: 5px
        display: flex
        flex-direction: column
        align-items: center
        cursor: pointer
        &:hover
            background-color: var(--background)
        &--draft
            opacity: 0.6
