@use '../utils/_variables' as *
@use '../utils/_mixins' as *

// Benne
@import url('https://fonts.googleapis.com/css2?family=Baloo+Da+2:wght@400..800&family=Benne&display=swap')

// Delius
@import url('https://fonts.googleapis.com/css2?family=Baloo+Da+2:wght@400..800&family=Benne&family=Delius&display=swap')

// Noto
@import url('https://fonts.googleapis.com/css2?family=Baloo+Da+2:wght@400..800&family=Benne&family=Delius&family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap')

body
    margin: 0
    background-color: var(--background-subtle)
    // background-color: var(--background)
    color: var(--text-high)
    transition: background-color $colorTransition ease-in-out
    font-family: 'Noto Sans'

a
    text-decoration: none
h1, h2, h3, h4, h5, h6
    font-family: 'Delius', cursive

h1
    font-size: 42px
    text-align: center
h2
    text-align: center
    font-size: 36px
    @media (max-width: $tabletSize)
        font-size: 33px
    @media (max-width: $mobileSize)
        font-size: 30px
h3
    font-size: 30px
    text-align: center
    @media (max-width: $tabletSize)
        font-size: 27px
    @media (max-width: $mobileSize)
        font-size: 24px


    
.mainContainer
    width: 75%
    max-width: 1450px
    margin: 0 auto
    @media ( max-width: $mobileSize )
        width: 95%

.test
    height: 100vh

.danger
    color: var(--danger)
.success
    color: var(--success)
.warning
    color: var(--warning)
.info
    color: var(--info)

.favoriteStar
    color: #FFDC00

.spinner
    font-size: 30px
    animation: spin 1s steps(8) infinite
    transition: 0s

@keyframes spin 
    0% 
        transform: rotate(0deg)
    100%
        transform: rotate(360deg)

  


// fonts

// headeing Jomolhari
// Body -> Noto Sans Nabataean