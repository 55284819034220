@use '../utils/_variables' as *

footer
    height: 40px
    display: flex
    flex-direction: row
    justify-content: space-around
    align-items: center
    & a
        color: var(--text-high)
        &:hover
            color: var(--text-low)