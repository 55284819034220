@use '../utils/_variables' as *

.projectPreview
    &__warning
        width: fit-content
        position: sticky
        top: 0
        font-size: 25px
        margin: 0 auto
        padding: 10px 15px
        border: 2px dashed var(--warning-hovered)
        border-top: none
        background-color: var(--background)
        color: var(--warning-hovered)
    &__save
        position: fixed
        bottom: 40px
        right: 40px
        z-index: 1000
        border: none
        background-color: var(--form-success)
        color: var(--success)
        padding: 10px 15px
        border-radius: 5px
        border: 1px dashed var(--success)
        font-size: 18px
        cursor: pointer

    &__content
        margin-bottom: 20px
        border-bottom: 1px dashed var(--text-high)
        &__item 
            border: 1px solid var(--border)
            padding: 5px
            background-color: var(--background)
    &__skills
        display: flex
        gap: 10px
        flex-wrap: wrap
        &__item
            padding: 2.5px 5px
            border: 1px solid var(--text-high)
            border-radius: 5px
            display: flex
            flex-direction: row
            align-items: center
            gap: 3px
            & svg 
                cursor: pointer
            &__star
                color: var(--warning-hovered)
            &__emptyStar
                &:hover
                    color: var(--warning-hovered)
            &__remove
                position: relative
                color: var(--danger-hovered)
                background-color: var(--background-subtle)
                top: -13px
                right: -10px
    &__images
        display: flex
        flex-direction: row
        gap: 10px
        flex-wrap: wrap
        &__item