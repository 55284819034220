@use '../utils/_variables' as *

.projectItem
    width: 100%
    height: 220px
    display: flex
    flex-direction: row
    justify-content: start
    align-items: center
    transition: 0.2s ease-in-out
    @media (max-width: $tabletSize)
        height: fit-content
        flex-direction: column-reverse
        border-bottom: 1px solid var(--text-high)
        padding-bottom: 30px
    @media (max-width: $mobileSize)
        flex-direction: column
    &:hover
        background-color: var(--background)
        position: relative
        margin: -5px 0 5px 0
        box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px
        & .projectItem
            &__content
                // padding: 15px 10px
                &__date
                    border: 1px solid var(--text-high)
                    background-color: var(--background-subtle)
                    box-shadow: rgb(38, 57, 77) 0px 10px 10px -10px
                    margin: -30px -18px 0 0

    &__image
        height: 220px
        @media (max-width: $tabletSize)
            height: auto
            width: 100vw
        &__star
            color: var(--warning-hovered)
            font-size: 20px
            position: absolute
            padding: 15px 0 0 15px
        & img
            height: 100%
            width: calc( 220px * 390 / 220)
            object-fit: cover
            @media (max-width: $tabletSize)
                width: 100%
            @media (max-width: $mobileSize)
                width: 100vw
                height: calc( 100vw * 220 / 390 )
    &__content
        width: calc( 100% - ( 220px * 390 / 220) )
        height: calc( 100% - 20px )
        color: var(--text-high)
        padding: 10px
        display: flex
        flex-direction: column
        justify-content: space-between
        align-items: center
        @media (max-width: $tabletSize)
            width: 100%
            height: fit-content
        &__date
            align-self: end
            position: absolute
            margin: -23px -10px 0 0
            padding: 2.5px 7px
            // border: 1px solid var(--background-subtle)
            border: 1px solid rgba(0,0,0,0)
            // background-color: var(--background-subtle)
            transition: 0.3s ease-in-out
            @media (max-width: $tabletSize)
                position: initial
                margin: initial
                align-self: center
        &__title
            margin: 0
            @media (max-width: $tabletSize)
                margin: initial
        &__description
            width: 100%
        &__skills
            width: 100%
            display: flex
            flex-direction: row
            align-items: center
            justify-content: start
            gap: 15px
            @media (max-width: $tabletSize)
                justify-content: space-around
            @media (max-width: $mobileSize)
                gap: 2px
                row-gap: 10px
                flex-wrap: wrap
            &__item
                padding: 2.5px 7px
                border: 1px solid var(--text-high)
                border-radius: 5px
            
