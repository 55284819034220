@use '../utils/_variables' as *

.adminNav
    max-width: 95%
    display: flex
    justify-content: center
    align-items: center
    gap: 15px
    flex-wrap: wrap
    &__item
        padding: 5px 15px
        cursor: pointer
        border: 1px solid var(--border)
        background-color: var(--element)
        &:hover
            border: 1px solid var(--border-hovered)
            background-color: var(--element-hovered)
        &:active
            border: 1px solid var(--border-selected)
            background-color: var(--element-selected)
        &--selected
            padding: 5px 15px
            border: 1px dashed var(--text-high)
            background-color: var(--background-subtle)
            cursor: pointer