@use '../utils/_variables' as *

.homeProjects
    // min-height: 100vh
    display: flex
    flex-direction: column
    justify-content: start
    align-items: center
    gap: 50px
    &__title
    &__global
        width: 75%
        display: flex
        flex-direction: column
        align-items: center
        justify-content: center
        margin: auto 0
        
    &__container
        width: 100%
        // height: 220px
        display: flex
        flex-direction: row
        flex-wrap: wrap
        column-gap: calc( 100% - ( 550px * 3 ) )
        gap: 2%
        row-gap: 45px
        justify-content: space-around
        // overflow: hidden
        // display: grid
        // row-gap: 5vh
        // grid-template-columns: 1fr 1fr 1fr
        // justify-content: center
        margin-bottom: 50px
        transition: 0.2s ease-in-out
        @media ( max-width: $mobileSize )
            width: 100vw
            flex-direction: column 
            align-items: center
            justify-content: center
            gap: 15px
        &--opened
            transition: 0.4s ease-in-out
            height: 750px
            @media (max-width: $tabletSize)
            @media (max-width: $mobileSize)
                height: fit-content
        &--closed
            transition: 0.4s ease-in-out
            height: 220px
            @media (max-width: $tabletSize)
            @media (max-width: $mobileSize)
                height: fit-content

.projectCard
    width: 390px
    height: 220px
    border-radius: 10px
    overflow: hidden
    @media (max-width: $mobileSize)
        width: 95vw
        height: calc( 95vw * 220 / 390 )
    &:hover
        .projectCard__layout
            background-color: rgba(0,0,0,0.75)
            @media ( max-width: $mobileSize )
                background-color: rgba(0,0,0,0)
        .projectCard__skills
            right: 10px
            @media ( max-width: $mobileSize )
        .projectCard__name
            position: relative
            left: 0
            z-index: 2
            @media ( max-width: $mobileSize )
                left: -110%
        .projectCard__description
            position: relative
            left: 0
            @media ( max-width: $mobileSize )
                left: -110%
    &__img
        width: 100%
        height: 100%
        object-fit: cover
    &__layout
        // width: 390px
        width: 100%
        // height: 225px
        height: calc( 100% + 5px )
        position: relative
        top: -227px
        // top: calc( -100% + 2px )
        background-color: rgba(0,0,0,0)
        z-index: 1
        transition: 0.2s ease-in-out
        @media (max-width: $mobileSize)
            width: 100%
            height: 101%
            top: calc( -100% - 5px )
    &__skills
        transition: 0.2s ease-in-out
        position: relative
        // top: -440px
        top: calc( -200% + 10px )
        right: -390px
        display: flex
        flex-direction: row
        justify-content: end
        align-items: center
        gap: 15px
        z-index: 2
        @media ( max-width: $mobileSize )
            right: 10px
        &__item
            background-color: var(--element)
            border: 1px solid var(--border)
            border-radius: 7px
            padding: 5px
            font-size: 13px
    &__name
        transition: 0.3s ease-in-out
        color: white
        font-weight: bold
        font-size: 22px
        z-index: 2
        position: relative
        left: -390px
        top: -430px
        text-align: center
        @media (max-width: $mobileSize)
            top: calc( -200% + 20px )
            left: -100%
    &__description
        transition: 0.3s ease-in-out
        width: 90%
        color: white
        font-size: 16px
        z-index: 2
        align-content: justify
        position: relative
        top: -430px
        left: -390px
        margin: 0 auto
        @media (max-width: $mobileSize)
            top: -180%
            left: -100%
            font-size: 14px





.moreButton
    display: flex
    justify-content: center
    align-items: center
    flex-direction: column
    margin-bottom: 50px
    & button
        text-decoration: none
        border: 2px solid var(--text-high)
        padding: 15px
        color: var(--text-high)
        background-color: var(--background-subtle)
        text-transform: uppercase
        letter-spacing: 2px
        position: relative
        display: inline-block
        &:hover
            cursor: pointer
        & span 
            position: relative
            z-index: 3
    & button::before 
        content: ""
        position: absolute
        top: 5px
        left: -2px
        width: calc(100% + 6px)
        height: calc(100% - 10px)
        background-color: var(--background-subtle)
        transition: all 0.35s ease-in-out
        transform: scaleY(1)
    & button:hover::before 
        transform: scaleY(0)
    & button::after 
        content: ""
        position: absolute
        left: 5px
        top: -5px
        width: calc(100% - 10px)
        height: calc(100% + 10px)
        background-color: var(--background-subtle)
        transition: all 0.35s ease-in-out
        transform: scaleX(1)
    & button:hover::after
        transform: scaleX(0)

