@use '../utils/_variables' as *

.welcome
    height: 100vh
    width: 96%
    margin: 0 auto
    display: flex
    flex-direction: row
    align-items: center
    justify-content: center
    gap: 8%
    @media ( max-width: $mobileSize )
        flex-direction: column-reverse
        gap: 35px
        
    &__desc
        width: 90%
        margin: 0 auto
        display: flex
        flex-direction: column
        align-items: center
        justify-content: center
        &__name
            font-size: 40px
            // font-size: clamp(3rem,5vw + 1rem,6.2rem)
            margin: 0
            text-align: center
            @media (max-width: $tabletSize)
                font-size: 38px
            @media (max-width: $mobileSize)
                font-size: 36px
        & h2 
            font-size: 25px
        &__social
            display: flex
            flex-direction: row
            justify-content: space-between
            flex-wrap: wrap
            gap: 15px
            @media (max-width: $mobileSize)
                width: 100%
                display: grid
                grid-template-columns: 1fr 1fr
            @media (max-width: 350px)
                display: flex
                flex-direction: column
                align-items: center
                
            &__button
                background-color: var(--element)
                border: 1px solid var(--border)
                border-radius: 8px
                color: var(--text-high)
                padding: 7px
                display: flex
                flex-direction: row
                align-items: center
                gap: 10px
                & p
                    margin: 0
                &__icon
                    height: 25px
                    border-radius: 5px
                    // & path
                    //     color: var(--text-high)
                &:hover
                    background-color: var(--element-hovered)
                    border: 1px solid var(--border-hovered)
                    color: var(--text-low)
                    cursor: pointer
                &:active
                    background-color: var(--element-selected)
                    border: 1px solid var(--border-selected)
    &__img
        width: 300px
        border-radius: 50%
        @media ( max-width: $tabletSize)
            width: 250px
        @media ( max-width: $mobileSize)
            max-width: 200px
            width: 70%
            margin-top: 20px