@use '../utils/_variables' as *

.btn
    width: fit-content
    padding: 5px 15px
    cursor: pointer
    font-size: 16px
    border-radius: 3px
    &--success
        // border: 1px solid var(--border)
        border: none
        background-color: var(--success)
        color: var(--background)
        &:hover
            // border: 1px solid var(--border-hovered)
            border: none
            background-color: var(--success-hovered)
        &:active
            // border: 1px solid var(--border-selected)
            border: none
            background-color: var(--element-selected)
    &--danger
        // border: 1px solid var(--border)
        border: none
        background-color: var(--danger)
        color: var(--background)
        &:hover
            // border: 1px solid var(--border-hovered)
            border: none
            background-color: var(--danger-hovered)
        &:active
            // border: 1px solid var(--border-selected)
            border: none
            background-color: var(--element-selected)