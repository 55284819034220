@use '../utils/_variables' as *

.skills
    // min-height: 100vh
    display: flex
    flex-direction: column
    justify-content: start
    margin-bottom: 50px
    @media (max-width: $tabletSize)
        min-height: initial
    &__container
        margin: auto 0
        display: flex
        flex-direction: row
        justify-content: space-around
        @media (max-width: $tabletSize)
            width: 80%
            display: grid
            grid-template-columns: 1fr 1fr
            row-gap: 50px
            margin: auto 0 auto auto
        &__bloc
            display: flex
            flex-direction: column
            gap: 20px
            @media (max-width: $tabletSize)
                align-items: start

            &__title
                @media (max-width: $mobileSize)
                    text-align: left
            &__item
                display: flex
                flex-direction: row
                gap: 15px
                font-size: 18px
                @media (max-width: $mobileSize)
                    font-size: 15px
                &__icon
                &__name