$color1: rgb(255,255,255)
$color2: rgb(255,255,255)
$color3: rgb(255, 253, 240)
$color4: rgb(255,255,255)

$red1: rgb(89, 28, 33)
$red2: rgb(140, 31, 40)
$red3: rgb(217, 37, 37)

$green1: #164C45
// $green2: 
// $green3: 



// SIZES
$tabletSize: 990px
$mobileSize: 680px





$colorTransition: 0.5s
$uiTransition: 0.2s



/* Default: light theme colours */
:root
    
    
    --light-background: #FBFDFF
    --light-background-subtle: #F4FAFF
    --light-element: #E6F4FE
    --light-element-hovered: #D5EFFF
    --light-element-selected: #C2E5FF
    --light-border: #ACD8FC
    --light-border-hovered: #8EC8F6
    --light-border-selected: #5EB1EF
    --light-solid: #0090FF
    --light-solid-hovered: #0588F0
    --light-text-low: #0D74CE
    --light-text-high: #113264

    --light-form-success: #D6F1DF
    --light-success: #46A758
    --light-success-hovered: #3E9B4F
    --light-danger: #E54D2E
    --light-danger-hovered: #DD4425
    --light-warning: #FFC53D
    --light-warning-hovered: #FFBA18
    --light-info: #3358D4
    --light-info-hovered: #3E63DD

    --dark-background: #0D1520
    --dark-background-subtle: #111927
    --dark-element: #0D2847
    --dark-element-hovered: #003362
    --dark-element-selected: #004074
    --dark-border: #104D87
    --dark-border-hovered: #205D9E
    --dark-border-selected: #2870BD
    --dark-solid: #0090FF
    --dark-solid-hovered: #3B9EFF
    --dark-text-low: #70B8FF
    --dark-text-high: #C2E6FF

    --dark-form-success: #113B29
    --dark-success: #46A758
    --dark-success-hovered: #53B365
    --dark-danger: #E54D2E
    --dark-danger-hovered: #EC6142
    --dark-warning: #FFC53D
    --dark-warning-hovered: #FFD60A
    --dark-info: #5472E4
    --dark-info-hovered: #3E63DD

    

    // --danger: red
    // --warning: amber
    // --succes: green
    // --info: blue, indigo, sky, cyan


body.dark
    --background: var(--dark-background)
    --background-subtle: var(--dark-background-subtle)
    --element: var(--dark-element)
    --element-hovered: var(--dark-element-hovered)
    --element-selected: var(--dark-element-selected)
    --border: var(--dark-border)
    --border-hovered: var(--dark-border-hovered)
    --border-selected: var(--dark-border-selected)
    --solid: var(--dark-solid)
    --solid-hovered: var(--dark-solid-hovered)
    --text-low: var(--dark-text-low)
    --text-high: var(--dark-text-high)

    --form-success: var(--dark-form-success)
    --success: var(--dark-success)
    --success-hovered: var(--dark-success-hovered)
    --danger: var(--dark-danger)
    --danger-hovered: var(--dark-danger-hovered)
    --warning: var(--dark-warning)
    --warning-hovered: var(--dark-warning-hovered)
    --info: var(--dark-info)
    --info-hovered: var(--dark-info-hovered)


body.light
    --background: var(--light-background)
    --background-subtle: var(--light-background-subtle)
    --element: var(--light-element)
    --element-hovered: var(--light-element-hovered)
    --element-selected: var(--light-element-selected)
    --border: var(--light-border)
    --border-hovered: var(--light-border-hovered)
    --border-selected: var(--light-border-selected)
    --solid: var(--light-solid)
    --solid-hovered: var(--light-solid-hovered)
    --text-low: var(--light-text-low)
    --text-high: var(--light-text-high)

    --form-success: var(--light-form-success)
    --success: var(--light-success)
    --success-hovered: var(--light-success-hovered)
    --danger: var(--light-danger)
    --danger-hovered: var(--light-danger-hovered)
    --warning: var(--light-warning)
    --warning-hovered: var(--light-warning-hovered)
    --info: var(--light-info)
    --info-hovered: var(--light-info-hovered)


/* if system theme is dark */
@media (prefers-color-scheme: dark)
    :root
        --background: var(--dark-background)
        --background-subtle: var(--dark-background-subtle)
        --element: var(--dark-element)
        --element-hovered: var(--dark-element-hovered)
        --element-selected: var(--dark-element-selected)
        --border: var(--dark-border)
        --border-hovered: var(--dark-border-hovered)
        --border-selected: var(--dark-border-selected)
        --solid: var(--dark-solid)
        --solid-hovered: var(--dark-solid-hovered)
        --text-low: var(--dark-text-low)
        --text-high: var(--dark-text-high)

        --form-success: var(--dark-form-success)
        --success: var(--dark-success)
        --success-hovered: var(--dark-success-hovered)
        --danger: var(--dark-danger)
        --danger-hovered: var(--dark-danger-hovered)
        --warning: var(--dark-warning)
        --warning-hovered: var(--dark-warning-hovered)
        --info: var(--dark-info)
        --info-hovered: var(--dark-info-hovered)

    // colors with radix-ui
// backgrounds
    // --background: 1
    // --background-subtle: 2

// components backgrounds
    // --element: 3
    // --element-hovered: 4 
    // --element-selected: 5

//borders
    // --border: 6
    // --border-hovered: 7
    // --border-selected: 8

// solid backgrounds (for high constrast bg like header background / shadow / logo )
    // --solid: 9
    // --solid-hovered: 10

//text
    // --text-low: 11
    // --text-high: 12

// Specials
    // --danger: red, ruby, tomato, crimson
    // --warning: yellow, amber, orange
    // --succes: green, teal, jade, grass, mint
    // --info: blue, indigo, sky, cyan