@use '../utils/_variables' as *

.skillCategories
    min-height: calc( 100vh - 210px)
    display: flex
    flex-direction: column
    align-items: center
    &__list
        display: flex
        flex-direction: row
        flex-wrap: wrap
        gap: 15px
        // margin: 50px 0
        margin: auto 0
        &__createBtn
            padding: 5px 15px
            border: 1px dashed #FFDC00
            background-color: #FFDC00
            font-size: 16px
            color: var(--text-high)
            display: flex
            align-items: center
            gap: 5px
            cursor: pointer
            &:hover, &:focus
                background-color: var(--warning)
        &__item
            padding: 5px 15px
            border: 1px solid var(--border)
            display: flex
            align-items: center
            gap: 5px
            cursor: pointer
            &:hover, &:focus
                outline: none
                border: 1px solid var(--border-hovered)
                background-color: var(--element-hovered)
            &:active
                border: 1px solid var(--border-selected)
                background-color: var(--element-selected)