@use '../utils/_variables' as *

.modalForm
    display: flex
    flex-direction: column
    gap: 15px
    @media (max-width: $tabletSize)
        width: 100%

    & input
        width: 80%
        border: 1px solid var(--background)
        outline: none
        border-radius: 4px
        padding: 10px 20px
        color: var(--text-high)
        background-color: var(--element)
        font-size: 18px
        &:focus
            // margin: -0.75px 0
            border: 1px solid var(--border-selected)
            background-color: var(--background-subtle)
        &:valid
            background-color: var(--form-success)

    
    & textarea
        outline: none
        border: 1px solid var(--background)
        width: 80%
        height: 50vh
        max-height: 350px
        border-radius: 8px
        background-color: var(--element)
        color: var(--text-high)
        padding: 10px 20px
        font-size: 18px
        resize: none
        &:focus
            background-color: var(--background-subtle)
            border: 1px solid var(--border-selected)
            // outline: 1px solid var(--border-selected)
            // margin: 0.1px
        &:valid
            background-color: var(--form-success)

    & select
        // width: 80%
        border: 1px solid var(--background)
        outline: none
        border-radius: 4px
        padding: 10px 20px
        color: var(--text-high)
        background-color: var(--element)
        font-size: 18px

    
    & button
        width: fit-content
        align-self: center
        outline: none
        border: none
        // border: 1px solid var(--border)
        border-radius: 6px
        background-color: var(--element)
        color: var(--text-high)
        padding: 8px 15px
        font-size: 17px
        font-weight: bold
        &:hover
            // border: 1px solid var(--border-hovered)
            background-color: var(--background-subtle)
            cursor: pointer
        &:focus
            // border: 1px solid var(--border-hovered)
            background-color: var(--background-subtle)
        // &:active
            border: 1px solid var(--border-selected)
    &__favorite
        width: 100%
        display: flex
        align-items: center
        gap: 15px
        & input
            width: 18px
            height: 18px
