@use '../utils/_variables' as *

.menu
    position: fixed
    top: 20px
    left: 20px
    display: flex
    z-index: 100
    @media (max-width: $mobileSize)
        display: none
    &__btn
        width: 40px
        height: 40px
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        border: 1px solid var(--border)
        border-radius: 5px
        background-color: var(--background-subtle)
        transition: background $colorTransition ease-in-out
        &:hover
            cursor: pointer
            background-color: var(--element-hovered)
        &:active
            background-color: var(--element-selected)
        &--opened
            .menu__btn__item--1
                transform: rotate(45deg)
                position: relative
                top: 10px
                transition: transform 0.5s 0.3s ease-in-out
            .menu__btn__item--2
                visibility: hidden
            .menu__btn__item--3
                transform: rotate(-45deg)
                position: relative
                bottom: 10px
                transition: transform 0.5s 0.3s ease-in-out
        
        & svg
            margin-top: -10px
            margin-bottom: -10px
            color: var(--text-high)
    &__container
        width: 50vw
        // height: 100%
        position: absolute
        left: 25vw
        overflow: hidden
        border: 1px solid var(--border)
        background-color: var(--element)
        border-radius: 5px
        padding: 10px 2vw
        margin: 0 auto
        display: flex
        flex-direction: row
        justify-content: space-around
        align-items: center
        gap: 4%
        transition: $colorTransition ease-in-out
        @media (max-width: $mobileSize)
            width: 75vw
            left: 12.5vw

        &:hover
            background-color: var(--text-high)
            & a 
                color: var(--background)
        & a
            color: var(--text-high)
            transition: color 0.2s ease-in-out
            &:hover
                color: var(--border-selected)
        &__separator
            width: 0
            // height: 50%
            height: 20px
            border: 1px solid var(--border)