@use '../utils/_variables' as *

.adminSkills
    min-height: calc( 100vh - 210px)
    display: flex
    flex-direction: column
    align-items: center
    &__filter
        display: flex
        align-items: center
        gap: 15px
        cursor: pointer
        &__item
            padding: 5px 10px
            &--selected
                border-bottom: 1px solid var(--warning-hovered)
                color: var(--warning-hovered)
    &__list
        width: 100%
        display: flex
        flex-direction: row
        justify-content: start
        flex-wrap: wrap
        gap: 15px
        // margin: 50px 0
        margin: auto 0
        &__createBtn
            padding: 5px 15px
            border: 1px dashed #FFDC00
            // background-color: var(--element)
            background-color: #FFDC00
            font-size: 16px
            color: var(--text-high)
            display: flex
            align-items: center
            gap: 5px
            cursor: pointer
            &:hover, &:focus
                background-color: var(--warning)

        &__item
            padding: 5px 15px
            border: 1px solid var(--border)
            display: flex
            align-items: center
            gap: 5px
            cursor: pointer
            &:hover, &:focus
                outline: none
                border: 1px solid var(--border-hovered)
                background-color: var(--element-hovered)
            &:active
                border: 1px solid var(--border-selected)
                background-color: var(--element-selected)
            // & svg 
            //     cursor: pointer
            //     font-size: 18px