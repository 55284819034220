@use '../utils/_variables' as *

.contact
    min-height: 100vh
    display: flex
    flex-direction: column
    align-items: center
    margin-bottom: 50px
    &__container
        width: 75%
        display: flex
        flex-direction: row
        justify-content: space-around
        align-content: center
        margin: auto 0
        @media (max-width: $tabletSize)
            width: 90%
            flex-direction: column-reverse
            align-items: center
        @media (max-width: $mobileSize)
            
        &__form
            width: 50%
            display: flex
            flex-direction: column
            flex-wrap: wrap
            align-items: center
            gap: 25px
            @media (max-width: $tabletSize)
                width: 100%

            &__input
                width: 80%
                border: 1px solid var(--background)
                outline: none
                border-radius: 8px
                padding: 10px 20px
                color: var(--text-high)
                background-color: var(--element)
                font-size: 18px
                &:focus
                    // margin: -0.75px 0
                    border: 1px solid var(--border-selected)
                    background-color: var(--background-subtle)
                &:valid
                    background-color: var(--form-success)
                
            &__id
                width: calc( 80% + 40px )
                display: flex
                justify-content: space-between
                gap: 2%
                @media (max-width: $mobileSize)
                    flex-direction: column
                    align-items: center
                    gap: 25px
                    width: 100%
                & input
                    // width: 40%
                    @media (max-width: $mobileSize)
                        width: 80%
            
            & textarea
                outline: none
                border: 1px solid var(--background)
                width: 80%
                height: 50vh
                max-height: 350px
                border-radius: 8px
                background-color: var(--element)
                color: var(--text-high)
                padding: 10px 20px
                font-size: 18px
                resize: none
                &:focus
                    background-color: var(--background-subtle)
                    border: 1px solid var(--border-selected)
                    // outline: 1px solid var(--border-selected)
                    // margin: 0.1px
                &:valid
                    background-color: var(--form-success)
            & button
                outline: none
                border: none
                // border: 1px solid var(--border)
                border-radius: 6px
                background-color: var(--element)
                color: var(--text-high)
                padding: 10px 40px
                font-size: 18px
                font-weight: bold
                &:hover
                    border: 1px solid var(--border-hovered)
                    background-color: var(--background-subtle)
                    cursor: pointer
                &:focus
                    border: 1px solid var(--border-hovered)
                    background-color: var(--background-subtle)
                &:active
                    border: 1px solid var(--border-selected)
        &__content
            width: 50%
            display: flex
            flex-direction: column
            align-items: center
            justify-content: start
            gap: 40px
            @media (max-width: $mobileSize)
                width: 100%
                margin-bottom: 40px
