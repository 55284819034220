@use '../utils/_variables' as *

.projectsPage
    &__loader
        width: 100vw
        height: 100vh
        position: absolute
        top: 0
        left: 0
        display: flex
        justify-content: center
        align-items: center
        & svg
            font-size: 50px
    &__container
        display: flex
        flex-direction: column
        align-items: center
        gap: 30px
        margin-top: 80px
        &__year
            width: 50dvw
            display: flex
            flex-direction: column
            align-items: center
            border-top: 3px solid var(--text-high)
            &__line
            &__text
                width: fit-content
                position: relative
                top: -25px
                background-color: var(--background-subtle)
                padding: 0 15px
                margin: 0
